import { CTA_STRINGS } from '@/utilities/constants/Cta';
import { decode } from 'html-entities';
import { useLocale } from '@/utilities/contexts/Locale';
import Embed from '@/components/atoms/Iframe';
import Image from 'next/image';
import ReactDOMServer from 'react-dom/server';
import VideoSchema from '@/components/molecules/VideoSchema';
import Testimonials from '@/components/molecules/Testimonials';
import Typography from '@/components/atoms/Typography';
import DOMPurify from 'isomorphic-dompurify';
import dynamic from 'next/dynamic';

// import CTA from '@/components/organisms/Cta';
const CTA = dynamic(() => import('@/components/organisms/Cta'));


export const Shortcodes = (content: any) => {
  const locale = useLocale();

  const imagePattern = /<img\s+([^>]*)>/g;
  const ctaPattern = /\[sc_cta\s+(.*)]/g;
  const iframePattern = /<iframe.*?src=["'](.*?)["'].*?<\/iframe>/g;
  const videoSchemaPattern =
    /(?:<p>)?\s*\[sc_video_schema\s+([^\]]+)\]([\s\S]*?)\[\/sc_video_schema\]\s*(?:<\/p>)?/g;
  const testimonial = /\[testimonial\s+(props="\[.*)]/g;

  const replacedVariable = content
    // @ts-ignore
    .replace(ctaPattern, (match: any, attributes: string) => {
      const attributeArray = Array.from(
        // This regex will capture any attribute from the
        // encoded string returned by wordpress graphql
        attributes.matchAll(
          /(\w+)=\s*(?:"|&#8220;|&#8221;|'|&#8217;|&raquo;|»)(.*?)(?:"|&#8220;|&#8221;|'|&#8217;|&raquo;|»|\s+\w+=|$)/g,
        ),
      );

      const attributeObject: any = {};

      const keys = [
        'title',
        'body',
        'btn_txt',
        'btn_link',
        'image_src',
        'image_alt',
      ];

      attributeArray.forEach(([_, key, value]) => {
        if (keys.includes(key)) {
          attributeObject[key] = decode(value.replaceAll('”', ''));
        }
      });

      const ctaContent = {
        title: attributeObject.title || CTA_STRINGS.shortcode.title[locale],
        body: attributeObject.body || CTA_STRINGS.shortcode.body[locale],
        btnText:
          attributeObject.btn_txt || CTA_STRINGS.shortcode.btnText[locale],
        btnUrl:
          attributeObject.btn_link || CTA_STRINGS.shortcode.btnUrl[locale],
        image: {
          altText: attributeObject.image_alt,
          mediaItemUrl: attributeObject.image_src,
          mediaDetails: {
            width: attributeObject.image_src ? 500 : undefined,
            height: attributeObject.image_src ? 500 : undefined,
          },
        },
      };

      if (ctaContent.title) {
        return ReactDOMServer.renderToString(
          <CTA
            isShortcode
            fontSizes={{
              heading: {
                xs: 4,
                md: 5,
                lg: 6
              }
            }}
            columns={12}
            index={0}
            cta={{
              content: [
                { title: ctaContent.title },
                { editor: ctaContent.body },
                {
                  links: [
                    {
                      link: {
                        url: ctaContent.btnUrl,
                        title: ctaContent.btnText,
                      },
                      style: 'yellow',
                    },
                  ],
                },
              ],
              customise: {
                background: 'purple-rain',
                width: 'container',
                // @ts-ignore
                image: ctaContent?.image,
              },
            }}
          />,
        );
      }
    })
    .replace(iframePattern, (match: any, src: string) => {
      return ReactDOMServer.renderToString(<Embed src={src} />);
    })
    .replace(
      videoSchemaPattern,
      // @ts-ignore
      (match: any, attributes: string, content: any) => {
        const attributeArray = Array.from(
          attributes.matchAll(
            /(\S+)=[\"']?((?:.(?![\"']?\s+(?:\S+)=|[>\"']))+.)[\"']?/g,
          ),
        );
        const attributeObject: any = {};

        attributeArray.forEach(([_, key, value]) => {
          attributeObject[key] = value.replaceAll('”', '');
        });

        const videoContent = {
          uploadDate: attributeObject.upload_date || '',
          id: attributeObject.id || '',
          name: attributeObject.name || '',
          description: content.trim(),
        };

        if (videoContent.id) {
          return ReactDOMServer.renderToString(
            <VideoSchema content={videoContent} />,
          );
        }
      },
    )
    .replace(imagePattern, (match: any, attributes: string, content: any) => {
      const attributeArray = Array.from(
        attributes.matchAll(
          /(\S+)=[\"']?((?:.(?![\"']?\s+(?:\S+)=|[>\"']))+.)[\"']?/g,
        ),
      );

      const attributeObject: any = {};

      attributeArray.forEach(([_, key, value]) => {
        attributeObject[key] = value.replace(/&#8221;/g, '');
      });

      if (attributeObject.src) {
        return ReactDOMServer.renderToString(
          <Image
            alt={attributeObject.alt}
            width={attributeObject.width || 600}
            height={attributeObject.height || 400}
            src={attributeObject.src}
          />,
        );
      } else {
        return match;
      }
    })
    .replace(testimonial, (match: any, props: string, content: any) => {
      let parsedProps: any = props
        .replace('props="', '')
        .replaceAll('"', '')
        .replaceAll("'", '"');

      parsedProps = JSON.parse(parsedProps);

      const editorItem = parsedProps.find((el: any) => el?.editor);

      editorItem.editor = ReactDOMServer.renderToString(
        <Typography
          style="italic"
          xs={3}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              editorItem.editor.replace('<p>', '<p>"').replace('</p>', '"</p>'),
            ),
          }}
        />,
      );

      return ReactDOMServer.renderToString(
        <Testimonials
          design="simple"
          testimonials={[
            {
              testimonial: {
                content: parsedProps.filter((item: any) => {
                  return (
                    Object.keys(item).filter((itemKey) =>
                      ['author', 'editor', 'link'].includes(itemKey),
                    ).length > 0
                  );
                }),
                media: parsedProps.filter((item: any) => {
                  return (
                    Object.keys(item).filter((itemKey) =>
                      ['logo', 'image'].includes(itemKey),
                    ).length > 0
                  );
                }),
              },
            },
          ]}
        />,
      );
    });

  return replacedVariable;
};

export default Shortcodes;
