import styles from './VideoSchema.module.scss';

import Embed from '@/components/atoms/Iframe';
import { formatISO8601 } from '@/utilities/helpers/FormatDate';

interface typeVideoSchema {
  content: {
    uploadDate?: string;
    id?: string;
    name?: string;
    description?: string;
  };
}

const VideoSchema = (props: typeVideoSchema) => {
  const { content } = props;

  const src = content.id ? `https://www.youtube.com/embed/${content.id}` : '';

  return src ? (
    <div className={styles.root}>
      <div itemProp="video" itemScope itemType="http://schema.org/VideoObject">
        {content.uploadDate && (
          <meta
            itemProp="uploadDate"
            content={formatISO8601(content.uploadDate)}
          />
        )}
        {content.id && (
          <>
            <meta
              itemProp="thumbnailUrl"
              content={`https://i.ytimg.com/vi/${content.id}/hqdefault.jpg`}
            />
            <meta
              itemProp="embedUrl"
              content={`https://www.youtube.com/watch?v=${content.id}`}
            />
          </>
        )}
        {content.name && <meta itemProp="name" content={content.name} />}
        {content.description && (
          <meta itemProp="description" content={content.description} />
        )}
        <Embed src={src} />
      </div>
    </div>
  ) : null;
};

export default VideoSchema;
