import styles from './HeroSlider.module.scss';

import dynamic from 'next/dynamic';

import { SliderIntro } from '@/components/molecules/HeroSlider/Intro';
import { SliderMobileImage } from '@/components/molecules/HeroSlider/MobileImage';
import { SliderStaticImage } from '@/components/molecules/HeroSlider/StaticImage';
import typeSlider from '@/utilities/types/Slider';

const Slider = dynamic(() => import('@/components/molecules/Slider'));

interface HeroSliderProps {
  content: typeSlider;
}

const HeroSlider = (props: HeroSliderProps) => {
  const { content } = props;

  return (
    <section className={styles.root}>
      {content.content && <SliderIntro content={content.content} />}
      <div className={styles.container}>
        {content.slides && content.slides.length === 1 && (
          <SliderStaticImage
            content={{
              image: content.slides[0].image,
              features: content.slides[0].features,
              caption: content.slides[0].caption
            }}
          />
        )}

        {content?.mobileImage && (
          <SliderMobileImage content={content?.mobileImage} />
        )}

        {content.slides && content.slides.length > 1 && (
          <Slider content={content} />
        )}
      </div>
    </section>
  );
};

export default HeroSlider;
