import WEBSITE from '@/utilities/constants/Website';

export function formatDateRange(dateFrom: string | undefined, dateTo: string | undefined, locale: string) {

  const fromDate = dateFrom ? new Date(parseDateString(dateFrom)) : null;
  const toDate = dateTo ? new Date(parseDateString(dateTo)) : null;

  if (fromDate) {
    const fromFormatter = new Intl.DateTimeFormat(WEBSITE.dateLocales[locale], { month: 'long', day: 'numeric', year: 'numeric' });
    const fromDay = fromDate.getDate();
    const fromMonth = fromFormatter.format(fromDate).split(' ')[1];

    const fromYear = fromDate.getFullYear();
    const fromSuffix = getOrdinalSuffix(fromDay);

    if (toDate) {
      const toFormatter = new Intl.DateTimeFormat(WEBSITE.dateLocales[locale], { month: 'long', day: 'numeric', year: 'numeric' });
      const toDay = toDate.getDate();
      const toMonth = toFormatter.format(toDate).split(' ')[1];
      const toYear = toDate.getFullYear();
      const toSuffix = getOrdinalSuffix(toDay);

      // Check if both dates are the same
      if (fromDate.getTime() === toDate.getTime()) {
        return `${fromMonth} ${fromDay}${fromSuffix}, ${fromYear}`;
      }

      // Check if the months and years are the same
      if (fromMonth === toMonth && fromYear === toYear) {
        return `${fromMonth} ${fromDay}${fromSuffix} - ${toDay}${toSuffix}, ${fromYear}`;
      } else {
        return `${fromMonth} ${fromDay}${fromSuffix}, ${fromYear} - ${toMonth} ${toDay}${toSuffix}, ${toYear}`;
      }
    } else {
      // If there's no dateTo, just return the dateFrom
      return `${fromMonth} ${fromDay}${fromSuffix}, ${fromYear}`;
    }
  } else {
    return null;
  }
}

export function getOrdinalSuffix(day: number) {
  if (day > 3 && day < 21) return 'th'; // 11th, 12th, 13th, etc.
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export function parseDateString(dateStr: string) {
  const [day, month, year] = dateStr.split('/').map(Number);
  return new Date(year, month - 1, day);
}

export function formatISO8601(dateStr: string) {
  try {
    const cleanDate = dateStr.replace(/&#\d+;/g, '').replace(/"/g, '');
    const [year, month, day] = cleanDate.split('-').map(Number);
    const date = new Date(Date.UTC(year, month - 1, day));
    return date.toISOString().replace('T', ' ').replace('Z', '');
  } catch (error) {
    console.error('Invalid date format:', error);
    return dateStr;
  }
}
