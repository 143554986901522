import { IconProps } from '@/utilities/types/Icon';

const Netherlands = (props: IconProps) => {
  const { height = 24, width = 24 } = props;

  return (
    <svg
      clipRule="evenodd"
      fill="none"
      fillRule="evenodd"
      height={height}
      viewBox="0 0 16 16"
      width={width}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.068 11.75A7.964 7.964 0 0 0 16 8a7.964 7.964 0 0 0-.932-3.75H.932A7.965 7.965 0 0 0 0 8c0 1.355.337 2.632.932 3.75h14.136Z"
        fill="#EEE"
      />
      <path d="M15.514 10.75a8.003 8.003 0 0 1-15.03 0h15.03Z" fill="#0052B4" />
      <path d="M15.514 5.25H.484a8.003 8.003 0 0 1 15.03 0Z" fill="#A2001D" />
    </svg>
  );
};

export default Netherlands;
