import '@/styles/index.scss';
import '@/styles/vendors/gradient.scss';
import '@/styles/vendors/highlight.scss';
import '@/styles/vendors/table.scss';
import '@/styles/vendors/wpforms.scss';

import { Noto_Sans, Poppins } from 'next/font/google';
import Algolia from '@/utilities/contexts/Algolia';
import cn from 'classnames';
import CountryCode from '@/utilities/contexts/CountryContext';
import CriticalBannerCookie from '@/utilities/contexts/CriticalBanner';
import Domain from '@/utilities/contexts/Domain';
import Locale from '@/utilities/contexts/Locale';
import Nonce from '@/utilities/contexts/Nonce';
import NonCriticalBannerCookie from '@/utilities/contexts/NonCriticalBanner';
import PageLoader from '@/components/atoms/PageLoader';
import SegmentProvider from '@/utilities/contexts/segment/SegmentProvider';
import type { AppProps } from 'next/app';
import Website from '@/utilities/contexts/Website';

const notosans = Noto_Sans({
  style: ['normal', 'italic'],
  subsets: ['latin'],
  variable: '--noto-sans',
  weight: ['400', '500', '700'],
  display: 'swap',
});

const poppins = Poppins({
  style: ['normal'],
  subsets: ['latin'],
  variable: '--poppins',
  weight: ['700'],
  display: 'swap',
});

const App = (props: AppProps) => {
  const { Component, pageProps } = props;

  return (
    <CountryCode>
      <Domain>
        <Website>
          <Locale>
            <CriticalBannerCookie>
              <NonCriticalBannerCookie>
                <Algolia>
                  <SegmentProvider>
                    <div className={cn(notosans.variable, poppins.variable)}>
                      <Nonce>
                        <PageLoader />
                        <Component {...pageProps} />
                      </Nonce>
                    </div>
                  </SegmentProvider>
                </Algolia>
              </NonCriticalBannerCookie>
            </CriticalBannerCookie>
          </Locale>
        </Website>
      </Domain>
    </CountryCode>
  );
};

export default App;
