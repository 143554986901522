import styles from './Testimonial.module.scss';

import Column from '@/components/atoms/Column';
import DOMPurify from 'isomorphic-dompurify';
import Row from '@/components/atoms/Row';
import cn from 'classnames';
import dynamic from 'next/dynamic';
import typeTestimonial from '@/utilities/types/Testimonial';
import { Fragment } from 'react';
import Stats from '@/components/molecules/Stats';
import ArrowRight from '@/icons/maggie/ArrowRight';
import Typography from '@/components/atoms/Typography';

const Button = dynamic(() => import('@/components/atoms/Button'));
const Chip = dynamic(() => import('@/components/atoms/Chip'));
const Image = dynamic(() => import('next/image'));

interface TestimonialProps {
  testimonial: typeTestimonial;
  design?: string;
}

const Testimonial = (props: TestimonialProps) => {
  const { testimonial, design } = props;

  const imageMedia = testimonial.media.filter((object) => object.image);
  const logoMedia = testimonial.media.filter(
    (object) => object.logo !== undefined,
  );

  return (
    <div
      className={cn(design && styles[design], styles.root, {
        [styles.withoutImage]: imageMedia.length === 0,
      })}
    >
      <Row>
        <Column xs={12} lg={imageMedia.length ? 7 : 12}>
          <div className={styles.content}>
            {logoMedia.map((media, index) => (
              <Fragment key={index}>
                {media.logo?.logo?.mediaItemUrl && (
                  <div className={styles.logoWraper}>
                    <Image
                      alt={media.logo.logo.altText}
                      className={cn(styles.desktop, styles.logo)}
                      height={media.logo.logo.mediaDetails.height || 80}
                      src={media.logo.logo.mediaItemUrl}
                      style={{
                        maxHeight: media.logo.height,
                        width: media.logo.height && 'auto',
                      }}
                      width={media.logo.logo.mediaDetails.width || 80}
                    />
                  </div>
                )}
              </Fragment>
            ))}
            {testimonial.content.map((content, index) => (
              <Fragment key={index}>
                {content?.stats && (
                  <div className={styles.stats}>
                    {content.stats.map((stat: any, index: number) => (
                      <Stats stats={stat} style="bold" key={index} />
                    ))}
                  </div>
                )}
                {content.editor && (
                  <div className={styles.editor}>
                    <Typography xs={3} lg={4}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(content.editor),
                        }}
                      />
                    </Typography>
                  </div>
                )}

                {content.author && (
                  <div className={cn(styles.author)}>
                    {content.author.avatar?.mediaItemUrl && (
                      <Image
                        alt={content.author.avatar.altText}
                        height={56}
                        src={content.author.avatar.mediaItemUrl}
                        width={56}
                      />
                    )}
                    {content.author.name && (
                      <Typography weight="bold" xs={2} lg={3}>
                        {`${content.author.name}${
                          design && design === 'icons' ? `,` : ''
                        }`}
                      </Typography>
                    )}
                    {content.author.title && (
                      <Typography xs={2} lg={3}>
                        {content.author.title}
                      </Typography>
                    )}
                  </div>
                )}

                {content.chip && (
                  <Chip className={styles.chip}>{content.chip}</Chip>
                )}

                {content.link && content.link.title && content.link.url && (
                  <Button
                    after={<ArrowRight width={16} height={16} />}
                    className={styles.link}
                    external={content.link.target ? true : false}
                    href={content.link.url}
                    size="large"
                    style="secondary"
                  >
                    {content.link.title}
                  </Button>
                )}
              </Fragment>
            ))}
          </div>
        </Column>
        {imageMedia.length > 0 ? (
          <Column xs={12} lg={5}>
            {imageMedia.map((media, index) => (
              <Fragment key={index}>
                {media.image && (
                  <div className={styles.image}>
                    <Image
                      alt={media.image.altText}
                      height={media.image.mediaDetails.height || 385}
                      src={media.image.mediaItemUrl}
                      width={media.image.mediaDetails.width || 505}
                    />
                  </div>
                )}
              </Fragment>
            ))}
          </Column>
        ) : (
          ''
        )}
      </Row>
    </div>
  );
};

export default Testimonial;
