import Image from 'next/image';
import typeImage from "@/utilities/types/Image";
import styles from './HeroSlider.module.scss';

interface SliderMobileImageProps {
  content: typeImage;
}

export const SliderMobileImage = (props: SliderMobileImageProps) => {
  const { content } = props;
  
  return(
    <div className={styles.mobile}>
      {content && content.mediaItemUrl && content.altText && (
        <Image
          priority
          src={content.mediaItemUrl}
          alt={content.altText}
          width={350}
          height={350}
        />
      )}
    </div>
  );
}