export const CTA_STRINGS: any = {
  search: {
    au: 'Search',
    de: 'Suche',
    en: 'Search',
    es: 'Buscar',
    fr: 'Rechercher',
    nl: 'Zoeken',
    'pt-br': 'Buscar',
    us: 'Search',
  },
  shortcode: {
    body: {
      au: 'Eliminate manual tasks and streamline your operations.',
      de: 'Eliminieren Sie manuelle Aufgaben und optimieren Sie Ihre Abläufe.',
      en: 'Eliminate manual tasks and streamline your operations.',
      es: 'Elimine las tareas manuales y agilice sus operaciones.',
      fr: 'Éliminez les tâches manuelles et rationalisez vos opérations.',
      nl: 'Elimineer handmatige taken en stroomlijn uw activiteiten.',
      'pt-br': 'Elimine tarefas manuais e simplifique suas operações.',
      us: 'Eliminate manual tasks and streamline your operations.',
    },
    btnUrl: {
      au: 'https://app.safetyculture.com/sign-up?utm_source=safetyculture&utm_medium=article&utm_campaign=SEO_CTA&utm_content=sign_up',
      de: 'https://app.safetyculture.com/sign-up?lang=de&utm_source=safetyculture&utm_medium=article&utm_campaign=SEO_CTA&utm_content=sign_up',
      en: 'https://app.safetyculture.com/sign-up?utm_source=safetyculture&utm_medium=article&utm_campaign=SEO_CTA&utm_content=sign_up',
      es: 'https://app.safetyculture.com/sign-up?lang=es&utm_source=safetyculture&utm_medium=article&utm_campaign=SEO_CTA&utm_content=sign_up',
      fr: 'https://app.safetyculture.com/sign-up?lang=fr&utm_source=safetyculture&utm_medium=article&utm_campaign=SEO_CTA&utm_content=sign_up',
      nl: 'https://app.safetyculture.com/sign-up?lang=nl&utm_source=safetyculture&utm_medium=article&utm_campaign=SEO_CTA&utm_content=sign_up',
      'pt-br':
        'https://app.safetyculture.com/sign-up?lang=pt-br&utm_source=safetyculture&utm_medium=article&utm_campaign=SEO_CTA&utm_content=sign_up',
      us: 'https://app.safetyculture.com/sign-up?utm_source=safetyculture&utm_medium=article&utm_campaign=SEO_CTA&utm_content=sign_up',
    },
    btnText: {
      au: 'Get started for FREE',
      de: 'KOSTENLOS loslegen',
      en: 'Get started for FREE',
      es: 'Empezar de forma GRATUITA',
      fr: 'Débuter GRATUITEMENT',
      nl: 'Ga GRATIS aan de slag',
      'pt-br': 'Comece a usar GRATUITAMENTE',
      us: 'Get started for FREE',
    },
    title: {
      au: 'Digitize the way you work',
      de: 'Digitize the way you work',
      en: 'Digitize the way you work',
      es: 'Digitalice su forma de trabajar',
      fr: 'Numérisez votre façon de travailler',
      nl: 'Digitaliseer uw manier van werken',
      'pt-br': 'Digitalize a maneira como você trabalha',
      us: 'Digitize the way you work',
    },
  },
};
