import styles from './Typography.module.scss';

import cn from 'classnames';
import { createElement, HTMLAttributes } from 'react';

interface TypographyProps extends Omit<HTMLAttributes<HTMLElement>, 'style'> {
  align?: 'left' | 'center' | 'right';
  className?: string;
  decoration?: 'line-through' | 'underline';
  family?: 'code' | 'noto-sans' | 'poppins';
  itemProp?: string;
  style?: 'italic' | 'normal' | 'oblique';
  tag?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'p'
    | 'span'
    | 'div'
    | 'section'
    | 'article';
  transform?: 'lowercase' | 'uppercase';
  weight?: 'regular' | 'medium' | 'bold';
  xs: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

const Typography = (props: TypographyProps) => {
  const {
    align,
    children,
    className,
    decoration,
    family,
    itemProp,
    style,
    tag = 'div',
    transform,
    weight,
    xs,
    sm,
    md,
    lg,
    xl,
    ...rest
  } = props;

  return createElement(
    tag,
    {
      className: cn(
        align && styles[align],
        className,
        decoration && styles[decoration],
        family && styles[family],
        style && styles[style],
        styles.root,
        transform && styles[transform],
        weight && styles[weight],
        xs && styles[`xs-${xs}`],
        sm && styles[`sm-${sm}`],
        md && styles[`md-${md}`],
        lg && styles[`lg-${lg}`],
        xl && styles[`xl-${xl}`],
      ),
      itemProp: itemProp,
      ...rest,
    },
    children,
  );
};

export default Typography;
