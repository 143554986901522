import styles from './HeroSlider.module.scss';

import { Fragment } from 'react';
import { typeSliderIntro } from '@/utilities/types/Slider';
import borders from '@/styles/modules/BorderColors.module.scss';
import Buttons from '@/components/molecules/Buttons';
import Chip from '@/components/atoms/Chip';
import cn from 'classnames';
import Column from '@/components/atoms/Column/Column';
import Container from '@/components/atoms/Container/Container';
import DOMPurify from 'isomorphic-dompurify';
import Row from '@/components/atoms/Row';
import Typography from '@/components/atoms/Typography';

interface SliderIntroProps {
  content: typeSliderIntro[];
}

export const SliderIntro = (props: SliderIntroProps) => {
  const { content } = props;

  return (
    <Container key={'intro'}>
      <Row justify="center">
        <Column xs={12} md={12} lg={10} className={styles.introWrapper}>
          <div className={styles.intro}>
            {content.map((item, index: number) => (
              <Fragment key={index}>
                {item.chip && (
                  <Chip
                    className={cn(
                      item?.color && borders[item.color],
                      styles.chip,
                    )}
                  >
                    {item.chip}
                  </Chip>
                )}
                {item?.title && (
                  <Typography
                    xs={6}
                    md={7}
                    lg={9}
                    tag="h1"
                    family="poppins"
                    className={styles.title}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(item.title),
                      }}
                    />
                  </Typography>
                )}
                {item?.editor && (
                  <Typography xs={3} md={4} tag="div" className={styles.text}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(item.editor),
                      }}
                    />
                  </Typography>
                )}
                {item.links && (
                  <Buttons buttons={item?.links} className={styles.links} />
                )}
              </Fragment>
            ))}
          </div>
        </Column>
      </Row>
    </Container>
  );
};
