import German from '@/icons/maggie/German';
import Netherlands from '@/icons/maggie/Netherlands';
import dynamic from 'next/dynamic';

const UsersIcon = dynamic(() => import('@/icons/maggie/Users'));
const GreatBritain = dynamic(() => import('@/icons/maggie/GreatBritain'));
const France = dynamic(() => import('@/icons/maggie/France'));
const Spain = dynamic(() => import('@/icons/maggie/Spain'));
const Brasil = dynamic(() => import('@/icons/maggie/Brasil'));
const BriefcaseIcon = dynamic(() => import('@/icons/maggie/Briefcase'));
const CircleQuestionIcon = dynamic(
  () => import('@/icons/maggie/CircleQuestion'),
);
const MessageIcon = dynamic(() => import('@/icons/maggie/Message'));

interface GetIconProps {
  name: string;
  size: number;
}

const GetIcon = (props: GetIconProps) => {
  const { name, size } = props;

  switch (name) {
    case 'briefcase':
      return <BriefcaseIcon width={size} height={size} />;
    case 'circle-question':
      return <CircleQuestionIcon width={size} height={size} />;
    case 'message':
      return <MessageIcon width={size} height={size} />;
    case 'users':
      return <UsersIcon width={size} height={size} />;
    case 'en':
      return <GreatBritain width={size} height={size} />;
    case 'fr':
      return <France width={size} height={size} />;
    case 'es':
      return <Spain width={size} height={size} />;
    case 'de':
      return <German width={size} height={size} />;
    case 'nl':
      return <Netherlands width={size} height={size} />;
    case 'pt-br':
      return <Brasil width={size} height={size} />;
    default:
      return undefined;
  }
};

export default GetIcon;
